function payDataBuild(displayData, rateCard, associatesNS, date, weekNo, depot, user){
    let dataSend = []
    let dataBuild = {}
    displayData?.map(d =>{
    let sk_no = ''
    associatesNS?.map(a =>{
        if(d.associate_name == a.name){
        sk_no = a.account_no
        }
    })
    rateCard?.map(r =>{
        if(d.category == r.schedule_shortcode){
        dataBuild = {
            category: d.category,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.qty,
            sub_total: d.qty*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
        }
        }
    })
    dataSend.push(dataBuild)
    dataBuild = {}

    if(d.unlinked_miles){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'MILES'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.unlinked_miles,
            sub_total: d.unlinked_miles*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            /*orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes*/
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.unlinked_congestion){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'FL-CC'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: 0,
            //route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.unlinked_congestion,
            sub_total: d.unlinked_congestion*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            /*orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes*/
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.unlinked_tool_crossing){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'TOLL'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: 0,
            //route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.unlinked_tool_crossing,
            sub_total: d.unlinked_tool_crossing*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            /*orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes*/
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.unlinked_away){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == '0hr-AWAY'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.unlinked_away,
            sub_total: d.unlinked_away*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.orh_late_wave){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'LATE'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.orh_late_wave,
            sub_total: d.unlinked_away*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.rescue2){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == '2hr-RESC'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.rescue2,
            sub_total: d.rescue2*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.rescue4){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == '4hr-RESC'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.rescue4,
            sub_total: d.rescue4*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.rescue6){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == '6hr-RESC'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: r.rate_subtotal_in,
            qty: d.rescue6,
            sub_total: d.rescue6*r.rate_subtotal_in,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.support){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'SUPP'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: d.support,
            qty: 1,
            sub_total: d.support,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    if(d.deduction){
        rateCard?.map(r =>{
        if(r.schedule_shortcode == 'SUPP'){
            dataBuild = {
            category: r.schedule_shortcode,
            sk_no: sk_no,
            associate_name: d.associate_name,
            schedule_date: date,
            description: r.description,
            duration: r.duration,
            route: d.route,
            rate: d.deduction,
            qty: 1,
            sub_total: '-'+d.deduction,
            vat: r.vat_chargeable,
            week_no: weekNo,
            station: depot,
            orh_wave_time: d.orh_wave_time,
            orh_dispatch_time: d.orh_dispatch_time,
            orh_end_time: d.orh_end_time,
            orh_actual_duration: d.orh_actual_duration,
            orh_late_wave: d.orh_late_wave,
            notes: d.notes,
            orh_notes: d.orh_notes,
            updatedBy: user
            }
        }
        })
        dataSend.push(dataBuild)
    }
    dataBuild = {}

    })
    console.log(dataSend)
    return dataSend;
}
export default payDataBuild;