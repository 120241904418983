import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { DataGridPro, gridClasses, GridToolbar, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Papa from 'papaparse';
import { duration } from "@material-ui/core";
import columns from "./payment";
import Select from 'react-select';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AuthService from "../../services/auth.service";
import CircularProgress from '@mui/material/CircularProgress';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import Button from '@mui/material/Button';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';


const ODD_OPACITY = 0.2;
const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
}));

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', minHeight: '20px', fontSize: 'x-large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)'}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const PaymentDatabase = () =>{
    const [ data, setData ] = useState()
    const [ displayData, setDisplayData ] = useState([])
    const [ selectedIds, setSelectedIds ] = useState([])
    const [ hidenColumns, setHidenColumns ] = useState({id: false, document_no: false, orh_wave_time: false, orh_dispatch_time: false, orh_end_time: false, orh_actual_duration: false, orh_late_wave: false, orh_notes: false, vat: false})
    const [ openNew, setOpenNew ] = useState(false)
    const [ openCSV, setOpenCSV ] = useState(false)
    const [ csvData, setCsvData ] = useState([])
    const [ openDel, setOpenDel ] = useState(false)
    const [ namesSk, setNamesSk ] = useState([])
    const [ namesOption, setNamesOption ] = useState([])
    const [ name, setName ] = useState()
    const [ skNo, setSkNo ] = useState()
    const [ station, setStation ] = useState()
    const [ rateData, setRateData ] = useState([])
    const [ descriptionOption, setDescriptionOption ] = useState([])
    const [ description, setDescription ] = useState()
    const [ duration, setDuration ] = useState()
    const [ category, setCategory ] = useState()
    const [ rate, setRate ] = useState()
    const [ qty, setQty ] = useState(0)
    const [ subtotal, setSubtotal ] = useState()
    const [ vat, setVat ] = useState()
    const [ weekNo, setWeekNo ] = useState(new Date().getFullYear()+'-')
    const [ dateOpen, setDateOpen ] = useState(false)
    const [ date, setDate ] = useState()
    const [ routeNo, setRouteNo ] = useState()
    const [ notes, setNotes ] = useState()
    const [ waveTime, setWaveTime ] = useState()
    const [ dispatchTime, setDispatchTime ] = useState()
    const [ endTime, setEndTime ] = useState()
    const [ acutalDuration, setActualDuration ] = useState()
    const [ lateWave, setLateWave ] = useState()
    const [ orhNotes, setOrhNotes ] = useState() 
    const [ warningName, setWarningName ] = useState()
    const [ warningSkNo, setWarningSkNo ] = useState()
    const [ warningDescription, setWarningDescription ] = useState()
    const [ warningRouteNo, setWarningRouteNo ] = useState()
    const [ warningDate, setWarningDate ] = useState()
    const [ warningQty, setWarningQty ] = useState()
    const [ warningWeekNo, setWarningWeekNo ] = useState()
    const user = AuthService.getCurrentUser();
    const [ openEdit, setOpenEdit ] = useState(false)
    const [ categoryOption, setCategoryOption ] = useState([])
    const [ openSingle, setOpenSingle ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ openInvoices, setOpenInvoices ] = useState(false)
    const [ weeks, setWeeks ] = useState(new Date().getFullYear()+'-')
    const [ weeksWarning, setWeeksWarning ] = useState("")
    const [ invoiceMessage, setInvoiceMessage ] = useState("")
    const [ invoiceCheck, setInvoiceCheck ] = useState(false)


    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{fontSize: '16px', marginTop: 0}}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport csvOptions={{fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] , escapeFormulas: false}}/>
                <Button className="incGridButton" onClick={e => handleOpenCSV(e)}> 
                  <FileUploadOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  IMPORT
                </Button>
                <GridToolbarQuickFilter />
                <Button className="incGridButton" onClick={e => setOpenNew(true)}> 
                  <LibraryAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  NEW ENTRY
                </Button>
                {selectedIds.length > 0 ? 
                    <>
                        <Button className="incGridButton" onClick={handleOpenEdit}> 
                            <EditNoteOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  EDIT SELECTED
                        </Button>
                        <Button className="incGridButton" onClick={e => setOpenDel(true)}> 
                            <DeleteOutlineIcon fontSize="small" style={{marginRight: '7px'}}/>  DELETE SELECTED
                        </Button>
                    </>:
                    <>
                        <Button className="incGridButton" style={{color: 'gray'}}> 
                            <EditNoteOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  EDIT SELECTED
                        </Button>
                        <Button className="incGridButton" style={{color: 'gray'}}> 
                            <DeleteOutlineIcon fontSize="small" style={{marginRight: '7px'}}/>  DELETE SELECTED
                        </Button>
                    </>
                }
                <Button className="incGridButton" onClick={e => setOpenInvoices(true)}> 
                  <NoteAddOutlinedIcon fontSize="small" style={{marginRight: '7px'}}/>  INVOICING
                </Button>
            </GridToolbarContainer>
        );
    }

    useEffect(()=>{
        setLoading(true)
        UserService.getPaymentDatabase().then(
            response =>{
                setData(response.data)
                console.log(response.data)
                UserService.getAssociatesNamesSkno().then(
                    response =>{
                        setNamesSk(response.data)
                        const dataPlc = response.data
                        let dataSave = []
                        dataPlc?.map(row =>{
                            dataSave.push({value: row.name, label: row.name})
                        })
                        setNamesOption(dataSave)
                        UserService.getRateForPay().then(
                            response =>{
                                console.log(response.data)
                                setRateData(response.data)
                                const dataPlc = response.data
                                let dataSave = []
                                dataPlc?.map(row =>{
                                    dataSave.push({value: row.description, label: row.description})
                                })
                                setLoading(false)
                                setDescriptionOption(dataSave)
                            }
                        ).catch(err => console.log(err.message))
                    }
                ).catch(err => console.log(err.message))
            }
        ).catch(err => console.log(err.message))
       
       
    },[])

    useEffect(() =>{
        let dataPlc = []
        let weeksPlc = []
        data?.map(row =>{
            dataPlc.push({id: row.entry, document_no: row.document_no, category: row.category, sk_no: row.sk_no, associate_name: row.associate_name, schedule_date: row.schedule_date, description: row.description, duration: row.duration, route: row.route, rate: Number(row.rate).toFixed(4), qty: row.qty, sub_total: Number(row.sub_total).toFixed(4), vat: row.vat, week_no: row.week_no, station: row.station, notes: row.notes, updatedBy: row.updatedBy, orh_wave_time: row.orh_wave_time, orh_dispatch_time: row.orh_dispatch_time, orh_end_time: row.orh_end_time, orh_actual_duration: row.orh_actual_duration, orh_late_wave: row.orh_late_wave, orh_notes: row.orh_notes})
            //weeksPlc.push(row.week_no)
        })   
        setDisplayData(dataPlc)
        //setWeeks([...new Set(weeksPlc)])
    },[data])

   

    useEffect(()=>{
        console.log(selectedIds)
    },[selectedIds])
    
    const handleCloseNew = () =>{
        setName("")
        setSkNo("")
        setDescription("")
        setCategory("")
        setRouteNo("")
        setDate("")
        setDuration("")
        setRate("")
        setQty(0)
        setSubtotal("")
        setWeekNo(new Date().getFullYear()+'-')
        setStation("")
        setNotes("")
        setVat("")
        setWaveTime("")
        setDispatchTime("")
        setEndTime("")
        setActualDuration("")
        setLateWave("")
        setOrhNotes("")
        setOpenNew(false)
    }

    const handleOpenCSV = () =>{
        setOpenCSV(true)
    }

    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            const dataPlc = results.data
            let dataSave = []
            dataPlc?.map(row =>{
                dataSave.push({document_no: row['Document No'], category: row.Category, sk_no: row['Sk No'], associate_name: row['Associate Name'], schedule_date: row['Schedule Date'].split('/').length > 1 ? row['Schedule Date'].split('/')[2]+'-'+row['Schedule Date'].split('/')[1]+'-'+row['Schedule Date'].split('/')[0] : row['Schedule Date'], description: row.Description, duration: row.Duration, route: row.Route, rate: row.Rate, qty: row.Qty, sub_total: row.Subtotal, week_no: row['Week No'], station: row.Station, notes: row.Notes, updatedBy: row.updatedBy, orh_wave_time: row['Wave Time'], orh_dispatch_time: row['Dispatch Time'], orh_end_time: row['End Time'], orh_actual_duration: row['Actual Duration'], orh_late_wave: row['Late Wave'], orh_notes: row['Orh Notes'], vat: row.Vat})
            })
            setCsvData(dataSave)
          },
        });
    };

    const upload = () =>{
        UserService.insertBulkPayment(csvData).then(
            response =>{
                window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    const handleDelClose = () =>{
        setOpenDel(false)
    }

    const deleteSelected = () =>{
        UserService.deleteSelectedPayments(selectedIds).then(
            response =>{
                let dataPlc = displayData?.filter(row => !selectedIds.includes(row.id))
                console.log(dataPlc)
                /*let dataSave = []
                dataPlc?.map(row =>{
                    dataSave.push({id: row.entry, document_no: row.document_no, category: row.category, sk_no: row.sk_no, associate_name: row.associate_name, schedule_date: row.schedule_date, description: row.description, duration: row.duration, route: row.route, rate: row.rate, qty: row.qty, sub_total: row.sub_total, vat: row.vat, week_no: row.week_no, station: row.station, notes: row.notes, updatedBy: row.updatedBy, orh_wave_time: row.orh_wave_time, orh_dispatch_time: row.orh_dispatch_time, orh_end_time: row.orh_end_time, orh_actual_duration: row.orh_actual_duration, orh_late_wave: row.orh_late_wave, orh_notes: row.orh_notes})
                })*/
                setDisplayData(dataPlc)
                setOpenDel(false)
                //window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    const handleNameChange = (e) =>{
        setName(e.value)
        namesSk?.map(row =>{
            if(e.value == row.name){
                setSkNo(row.account_no)
                setStation(row.station)
            }
        })
    }

    const handleDescriptionChange = (e) =>{
        setDescription(e.value)
        rateData?.map(row =>{
            if(e.value == row.description){
                console.log(row)
                setDuration(row.duration)
                setCategory(row.schedule_shortcode)
                setRate(row.rate_subtotal_out)
                setVat(row.vat_chargeable)
            }
        })
    }

    const handleQty = (count) =>{
        setQty(count)
        setSubtotal(count*rate)
    }
    const handleRate = (value) =>{
        setRate(value)
        setSubtotal(qty*value)
    }

    const handleWeekNo = (value) =>{
        if(value.length <= 7){
            setWeekNo(value)
        }
    }

    const dateChange = (e) =>{
        setDate(new Date(e).getFullYear()+'-'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(e).getDate().toString().padStart(2, '0'))
    }

    const handleWaveTime = (time) =>{
        if(time.length <= 5){
            setWaveTime(time)
        }
        if(time.length == 2){
            setWaveTime(time+':')
        }
    }

    const handleDispatchTime = (time) =>{
        if(time.length <= 5){
            setDispatchTime(time)
        }
        if(time.length == 2){
            setDispatchTime(time+':')
        }
    }

    const handleEndTime = (time) =>{
        if(time.length <= 5){
            setEndTime(time)
        }
        if(time.length == 2){
            setEndTime(time+':')
        }
    }

    const hadleLateWave = (time) =>{
        if(time.length <= 5){
            setLateWave(time)
        }
        if(time.length == 2){
            setLateWave(time+':')
        }
    }

    function timeToMinutes(time){
        let minutes = (Number(time.split(':')[0])*60)+Number(time.split(':')[1]);
        return minutes;
    }
    function minutesToTime(minutes){
        let time = Math.floor(minutes/60).toString().padStart(2, '0')+':'+(minutes%60).toString().padStart(2, '0')
        return time
    }

    useEffect(()=>{
        if(dispatchTime && endTime){
            setActualDuration(minutesToTime(timeToMinutes(endTime)-timeToMinutes(dispatchTime)))
        }
    },[dispatchTime, endTime])

    const addEntry = () =>{
        if(name && skNo && description && date && routeNo && qty > 0 && weekNo.length == 7){
            const dataSend = {category: category, sk_no: skNo, associate_name: name, schedule_date: date, description: description, duration: duration, route: routeNo, rate: rate, qty: qty, sub_total: subtotal, vat: vat, week_no: weekNo, station: station, orh_wave_time: waveTime, orh_dispatch_time: dispatchTime, orh_end_time: endTime, orh_actual_duration: acutalDuration, orh_late_wave: lateWave, notes: notes, orh_notes: orhNotes, updatedBy: user.username}
            UserService.insertSinglePayment(dataSend).then(
                response =>{
                    window.location.reload(false)
                }
            ).catch(err => console.log(err.message))
        }else{
            if(!name){
                setWarningName('name required')
            }
            if(!skNo){
                setWarningSkNo('field required')
            }
            if(!description){
                setWarningDescription('description required')
            }
            if(!routeNo){
                setWarningRouteNo('route no required')
            }
            if(qty == 0){
                setWarningQty('must be bigger than 0')
            }
            if(weekNo.length < 7){
                setWarningWeekNo('week no required')
            }
            if(!date){
                setWarningDate('field required')
            }
        }
    }

    useEffect(() =>{
        if(name){
            setWarningName("")
        }
        if(skNo){
            setWarningSkNo("")
        }
        if(description){
            setWarningDescription("")
        }
        if(date){
            setWarningDate("")
        }
        if(routeNo){
            setWarningRouteNo("")
        }
        if(qty > 0){
            setWarningQty("")
        }
        if(weekNo.length == 7){
            setWarningWeekNo("")
        }
    },[name, skNo, description, date, routeNo, qty, weekNo])

    const handleCloseEdit = () =>{
        setName("")
        setSkNo("")
        setDescription("")
        setCategory("")
        setRouteNo("")
        setDate("")
        setDuration("")
        setRate("")
        setQty(0)
        setSubtotal("")
        setWeekNo(new Date().getFullYear()+'-')
        setStation("")
        setNotes("")
        setVat("")
        setWaveTime("")
        setDispatchTime("")
        setEndTime("")
        setActualDuration("")
        setLateWave("")
        setOrhNotes("")
        setOpenEdit(false)
    }

    const handleCloseSingle = () =>{
        setOpenSingle(false)
    }

    const update = () =>{
        let dataSend = []
        //dataSend = Object.fromEntries(Object.entries(dataSend).filter(([_, v]) => v != null && v != '' && v != new Date().getFullYear()+'-'));
        //dataSend.ids = selectedIds
        displayData?.map(row =>{
            selectedIds?.forEach(id =>{
                if(row.id == id){
                    dataSend.push(
                        {
                            entry: row.id,
                            category: category ? category : row.category, 
                            sk_no: skNo ? skNo : row.sk_no, 
                            associate_name: name ? name : row.associate_name, 
                            schedule_date: date ? date : row.schedule_date, 
                            description: description ? description : row.description, 
                            duration: duration ? duration : row.duration, 
                            route: routeNo ? routeNo : row.route, 
                            rate: rate ? rate : row.rate, 
                            qty: qty !== 0 ? qty : row.qty, 
                            sub_total: subtotal ? subtotal : row.sub_total, 
                            vat: vat ? vat : row.vat, 
                            week_no: weekNo !== new Date().getFullYear()+'-' ? weekNo : row.week_no, 
                            station: station ? station : row.station, 
                            orh_wave_time: waveTime ? waveTime : row.orh_wave_time, 
                            orh_dispatch_time: dispatchTime ? dispatchTime : row.orh_dispatch_time, 
                            orh_end_time: endTime ? endTime : row.orh_end_time, 
                            orh_actual_duration: acutalDuration ? acutalDuration : row.orh_actual_duration, 
                            orh_late_wave: lateWave ? lateWave : row.orh_late_wave, 
                            notes: notes ? notes : row.notes, 
                            orh_notes: orhNotes ? orhNotes : row.orh_notes, 
                            updatedBy: user.username})
                }
            })
        })
        dataSend?.map(row =>{
            row.sub_total = row.rate*row.qty
        })
        console.log(dataSend)
        UserService.updateBulkPayments(dataSend).then(
            response =>{
                console.log(response)
                const keys = Object.keys(dataSend[0])
                let dataPlc = []
                displayData?.map(row =>{
                    dataSend?.forEach(dRow =>{
                        for(let i = 0; i < keys.length; i++){
                            if(dRow.entry == row.id){
                                row[keys[i]] = dRow[keys[i]]
                            }
                        }
                        
                    })
                    dataPlc.push(row)  
                })
                setDisplayData(dataPlc)
                setName("")
                setSkNo("")
                setDescription("")
                setCategory("")
                setRouteNo("")
                setDate("")
                setDuration("")
                setRate("")
                setQty(0)
                setSubtotal("")
                setWeekNo(new Date().getFullYear()+'-')
                setStation("")
                setNotes("")
                setVat("")
                setWaveTime("")
                setDispatchTime("")
                setEndTime("")
                setActualDuration("")
                setLateWave("")
                setOrhNotes("")
                setOpenEdit(false)
                //window.location.reload(false)
            }
        ).catch(err => console.log(err.message))
    }

    useEffect(() =>{
        if(date){
            setDateOpen(false)
        }
    },[date])

    const handleOpenEdit = () =>{
        if(selectedIds.length == 1){
            displayData?.map(row =>{
                if(selectedIds[0] == row.id){
                    setName(row.associate_name)
                    setSkNo(row.sk_no)
                    setDescription(row.description)
                    setCategory(row.category)
                    setRouteNo(row.route)
                    setDate(row.schedule_date)
                    setRate(row.rate)
                    setQty(row.qty)
                    setSubtotal(row.sub_total)
                    setWeekNo(row.week_no)
                    setStation(row.station)
                    setNotes(row.notes)
                    setVat(row.vat)
                    setWaveTime(row.orh_wave_time)
                    setDispatchTime(row.orh_dispatch_time)
                    setEndTime(row.orh_end_time)
                    setActualDuration(row.orh_actual_duration)
                    setLateWave(row.orh_late_wave)
                    setOrhNotes(row.orh_notes)
                }
            })
        }
        setOpenEdit(true)
    }

    const handleWeeks = (value) =>{
        if(value.length <= 7){
            setWeeks(value)
        }
    }

    const createInvoices = () =>{
        if(weeks.length == 7){
            setInvoiceCheck(true)
            let invoiceData = [] 
            displayData?.map(row =>{
                if(row.week_no == weeks){
                    invoiceData.push(row)
                }
            })
            console.log(invoiceData)
            UserService.processInvoices(invoiceData).then(
                response =>{
                    if(response.data == 'ok'){
                        setInvoiceMessage('Invoices created.')
                        console.log(response)
                        setInvoiceCheck(false)
                    }else{
                        setInvoiceMessage('Something went wrong.')
                        setInvoiceCheck(false)
                    }
                }
            )
        }else{
            setWeeksWarning('week number required')
        }
    }

    useEffect(()=>{
        if(weeks.length == 7){
            setWeeksWarning("")
        }
    },[weeks])

    return(
        <div className='dashArch'>
            <h3 className="h3TitlesCorrection" style={{marginBottom: '40px'}}>Payment Database</h3>
            {/*<div className="searchBar1 fixed1 flex" >*/}
               {/* <input type="text" name='search' className="fullInput1" placeholder="search..."defaultValue={email} onChange={e => handleSearch(e)}  />*/}
                {/*<button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenNew(true)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>New entry</span>
                    <div class="fill-container"></div>
                </button>*/}
                {/*selectedIds.length == 1 ?
                <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenSingle(true)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Edit Sigle</span>
                    <div class="fill-container"></div>
                </button> :
                <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'gray', color:'white'}} onClick={e => setOpenNew(true)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Edit Sigle</span>
                    <div class="fill-container"></div>
                </button>
                */}
                {/*selectedIds.length > 0 ?
                    <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleOpenEdit}>
                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Edit selected</span>
                        <div class="fill-container"></div>
                    </button> :
                    <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'gray', color:'white'}} /*onClick={e => handleOpenAdd(e)}>
                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Edit selected</span>
                        <div class="fill-container"></div>
                    </button>
                */}
                {/*selectedIds.length > 0 ? 
                    <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenDel(true)}>
                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Delete selected</span>
                        <div class="fill-container"></div>
                    </button>
                :   
                    <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'gray', color:'white'}} /*onClick={e => handleOpenAdd(e)}>
                        <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Delete selected</span>
                        <div class="fill-container"></div>
                    </button>
                */}
                {/*<button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => handleOpenCSV(e)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Import CSV</span>
                    <div class="fill-container"></div>
                </button>
                <button class="buttonSkOn" style={{marginLeft: '10px', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenInvoices(true)}>
                    <span class="button-text" style={{fontSize: '16px', fontWeight: '600', color:'white'}}>Create Invoices</span>
                    <div class="fill-container"></div>
                </button>
            </div> */}
            <Box sx={{ height: 'fit-content', width: 'fit-content', paddingLeft: '0%', marginTop: 0}}>
                <StripedDataGridPro
                    pagination
                    checkboxSelection
                    rows={displayData}
                    
                    columns={columns}
                    initialState={{
                        pagination: {
                        paginationModel: {
                            pageSize: 50,
                        },
                        },
                    }}
                    sx={{fontSize: '14px', fontFamily: '', [`.${gridClasses.cell}.cold`]: {
                        backgroundColor: '#D35858',
                        color: '#1a3e72',
                    },
                    [`.${gridClasses.cell}.hot`]: {
                        backgroundColor: '#A6D358',
                        color: '#1a3e72',
                    },}}
                    pageSizeOptions={[50, 100, 200]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar
                    }}
                    slotProps={{ toolbar: { csvOptions: { fields: ['document_no', 'category', 'sk_no', 'associate_name', 'schedule_date', 'description', 'route', 'rate', 'qty', 'sub_total', 'week_no', 'station', 'notes', 'orh_wave_time', 'orh_dispatch_time', 'orh_end_time', 'orh_actual_duration', 'orh_late_wave', 'orh_notes', 'vat'] } } }}
                    loading={loading}
                    //processRowUpdate={params =>handleCellEdit(params)}
                    onRowSelectionModelChange={params => setSelectedIds(params)}
                    //onRowClick={params => console.log(params)}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    onColumnVisibilityModelChange={param => setHidenColumns(param)}    
                    columnVisibilityModel={hidenColumns}
                    //ignoreValueFormatterDuringExport
                    getCellClassName={(params) => {
                        if (params.field === 'city' || params.value == null) {
                        return '';
                        }
                        return params.value == 'Pass' ? 'hot' : params.value == 'Fail' ? 'cold' : '';
                    }}
                    {...displayData}
                />
            </Box> 
            
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openNew}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Create new entry</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Associate Name</label>
                                    <Select 
                                        options={namesOption}
                                        styles={colourStyles}
                                        value={{label: name, value: name}}
                                        onChange={e => handleNameChange(e)}
                                    /> 
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningName ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningName}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Account No</label>
                                    <input type="text" name="dlNo" className="lone" value={skNo} onChange={e => setSkNo(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningSkNo ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningSkNo}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Description</label>
                                    <Select 
                                        options={descriptionOption}
                                        styles={colourStyles}
                                        value={{label: description, value: description}}
                                        onChange={e => handleDescriptionChange(e)}
                                    /> 
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Category</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={category}  onChange={e => setCategory(e.target.value)} disabled></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningDescription ? 
                                    <p className="pClass pCorrection " style={{ color: '#ff3333'}}>{warningDescription}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Route No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={routeNo} onChange={e => setRouteNo(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Schedule Date</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={date} onClick={e => setDateOpen(true)} onChange={e => setDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    {warningRouteNo ? 
                                        <p className="pClass pCorrection " style={{ color: '#ff3333'}}>{warningRouteNo}</p> : ''
                                    }
                                </div>
                                <div className="flexComp">
                                    {warningDate ? 
                                        <p className="pClass pCorrection " style={{ color: '#ff3333', marginLeft: '1%'}}>{warningDate}</p> : ''
                                    }
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                </div>
                                <div className="flexComp ">
                                    <div className='calendarL'>
                                        {dateOpen == true ?
                                                <>
                                                    <Calendar  
                                                        onChange={e => dateChange(e)} 
                                                        style={{marginLeft: '200px'}}
                                                        value={date && date !== '0000-00-00' && date !== 'Null' ? new Date(date): new Date()} 
                                                        validRange = {{
                                                            start: new Date(),
                                                            end: null
                                                        }}
                                                        
                                                        className={'calendarX'}
                                                    />
                                                    {/*<CloseRoundedIcon onClick={e => setDateOpen(false)}>Close</CloseRoundedIcon>*/}
                                                </> 
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Rate</label>
                                    <input type="number" name="dlNo" className="lone" value={rate} onChange={e => handleRate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Qty</label>
                                    <input type="number" name="dlEx" className="inputEdit" value={qty} onChange={e => handleQty(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Subtotal</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={subtotal} disabled></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningQty ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningQty}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Week No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={weekNo} onChange={e => handleWeekNo(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Station</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={station} onChange={e => setStation(e.target.value)} disabled></input>
                                </div>
                            </div>
                            <div className="flexComp">
                                {warningWeekNo ? 
                                    <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{warningWeekNo}</p> : ''
                                }
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea  name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)} ></textarea>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Vat</label>
                                    <input type="text" name="dlNo" className="lone" value={vat} disabled></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Wave Time</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={waveTime} onChange={e => handleWaveTime(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Dispatch Time</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={dispatchTime} onChange={e => handleDispatchTime(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">End Time</label>
                                    <input type="text" name="dlNo" className="lone" value={endTime} onChange={e => handleEndTime(e.target.value)} ></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Actual Duration</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={acutalDuration} disabled></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Late Wave</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={lateWave} onChange={e => hadleLateWave(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Orh Notes</label>
                                    <textarea  name="dlNo" className="lone" value={orhNotes} onChange={e => setOrhNotes(e.target.value)} ></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={addEntry}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Add Entry</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseNew}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openCSV}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Upload CSV file</label></DialogTitle>
                    <DialogContent>
                        <input
                            type="file"
                            name="file"
                            accept=".csv"
                            onChange={changeHandler}
                            style={{ display: "block", margin: "10px auto" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={upload}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Upload</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setOpenCSV(false)}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openDel}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Are you sure you want to delete the selected lines?</label></DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={deleteSelected}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Yes</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleDelClose}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>No</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    fullScreen
                    open={openEdit}
                    //TransitionComponent={Transition}
                    //keepMounted
                    //onClose={handleClose}
                    //sx={{marginLeft: '0%', marginTop: '-40%'}}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Edit selected lines</label></DialogTitle>
                    <DialogContent>
                        <div style={{marginRight: '5%'}}>
                            <p>*blank fiels will not be changed</p>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Associate Name</label>
                                    <Select 
                                        options={namesOption}
                                        styles={colourStyles}
                                        value={{label: name, value: name}}
                                        onChange={e => handleNameChange(e)}
                                    /> 
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Account No</label>
                                    <input type="text" name="dlNo" className="lone" value={skNo} onChange={e => setSkNo(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Description</label>
                                    <Select 
                                        options={descriptionOption}
                                        styles={colourStyles}
                                        value={{label: description, value: description}}
                                        onChange={e => handleDescriptionChange(e)}
                                    /> 
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Category</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={category}  onChange={e => setCategory(e.target.value)} disabled></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Route No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={routeNo} onChange={e => setRouteNo(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Schedule Date</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={date} onClick={e => setDateOpen(true)} onChange={e => setDate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                </div>
                                <div className="flexComp ">
                                    <div className='calendarL'>
                                        {dateOpen == true ?
                                                <>
                                                    <Calendar  
                                                        onChange={e => {
                                                            dateChange(e)
                                                            setDateOpen(false)
                                                        }} 
                                                        style={{marginLeft: '200px'}}
                                                        value={date && date !== '0000-00-00' && date !== 'Null' ? new Date(date): new Date()} 
                                                        validRange = {{
                                                            start: new Date(),
                                                            end: null
                                                        }}
                                                        
                                                        className={'calendarX'}
                                                    />
                                                    {/*<CloseRoundedIcon onClick={e => setDateOpen(false)}>Close</CloseRoundedIcon>*/}
                                                </> 
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Rate</label>
                                    <input type="number" name="dlNo" className="lone" value={rate} onChange={e => setRate(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Qty</label>
                                    <input type="number" name="dlEx" className="inputEdit" value={qty} onChange={e => handleQty(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Subtotal</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={subtotal} onChange={e => setSubtotal(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Week No</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={weekNo} onChange={e => handleWeekNo(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Station</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={station} onChange={e => setStation(e.target.value)} disabled></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Notes</label>
                                    <textarea  name="dlNo" className="lone" value={notes} onChange={e => setNotes(e.target.value)} ></textarea>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Vat</label>
                                    <input type="text" name="dlNo" className="lone" value={vat} disabled></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Wave Time</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={waveTime} onChange={e => handleWaveTime(e.target.value)}></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Dispatch Time</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={dispatchTime} onChange={e => handleDispatchTime(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">End Time</label>
                                    <input type="text" name="dlNo" className="lone" value={endTime} onChange={e => handleEndTime(e.target.value)} ></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlEx" className="label2">Actual Duration</label>
                                    <input type="text" name="dlEx" className="inputEdit" value={acutalDuration} disabled></input>
                                </div>
                                <div className="flexComp ">
                                    <label for="origin" className="label2 correction">Late Wave</label>
                                    <input type="text" name="origin" className="inputEdit correction" value={lateWave} onChange={e => hadleLateWave(e.target.value)}></input>
                                </div>
                            </div>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Orh Notes</label>
                                    <textarea  name="dlNo" className="lone" value={orhNotes} onChange={e => setOrhNotes(e.target.value)} ></textarea>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={update}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Update</span>
                            <div class="fill-container"></div>
                        </button>
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleCloseEdit}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    {/*<button className="buttonSS" onClick={handleShowClose}>Close</button>*/}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    //fullScreen
                    open={openInvoices}
                    //TransitionComponent={Transition}
                    keepMounted
                    //onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle><label for="select" className="label" >Create Invoices</label></DialogTitle>
                    <DialogContent>
                        {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ? 
                        <div className="flex spaceing">
                            <div className="flexComp">
                                <label for="dlNo" className="label2">{invoiceMessage}</label>
                            </div>
                        </div> :
                        <>
                            <div className="flex spaceing">
                                <div className="flexComp">
                                    <label for="dlNo" className="label2">Week</label>
                                    <input type="text" name="dlNo" className="lone" value={weeks} onChange={e => handleWeeks(e.target.value)}></input>
                                </div>
                            </div>
                            {weeksWarning ? 
                                <p className="pClass pCorrection topC" style={{ color: '#ff3333'}}>{weeksWarning}</p> : ''
                            }
                            {invoiceCheck ?
                            <div className="flex spaceing" style={{marginTop: '60px'}}>
                                <div className="flexComp">
                                    <CircularProgress color="inherit" style={{marginLeft: '45%'}}/>
                                    <label for="dlNo" className="label2" style={{marginLeft: '32%'}}>Creating invoices...</label>
                                </div>
                            </div>: ''
                            }
                        </>
                        }
                    </DialogContent>
                    <DialogActions>
                        {invoiceMessage == 'Invoices created.' || invoiceMessage == 'Something went wrong.' ?
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={e => setInvoiceMessage("")} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>refresh</span>
                            <div class="fill-container"></div>
                        </button>:
                        invoiceCheck ?
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'gray', color:'white'}}  >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Create</span>
                            <div class="fill-container"></div>
                        </button>:
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={createInvoices} >
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Create</span>
                            <div class="fill-container"></div>
                        </button>
                        }
                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(255, 106, 106)', color:'white'}} onClick={e => {
                            setOpenInvoices(false)
                            setWeeks(new Date().getFullYear()+'-')
                            setWeeksWarning("")
                            }}>
                            <span class="button-text" style={{fontSize: '20px', fontWeight: '600', color:'white'}}>Close</span>
                            <div class="fill-container"></div>
                        </button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default PaymentDatabase;