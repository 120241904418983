let columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'table-header', width: 150, editable: false },
    {
        field: 'associate_name',
        headerName: 'Name',
        headerClassName: 'table-header',
        width: 250,
        editable: false,
    },
    {
        field: 'category',
        headerName: 'Service Type',
        headerClassName: 'table-header',
        width: 130,
        editable: false,
    },
    {
        field: 'qty',
        headerName: 'Qty',
        headerClassName: 'table-header',
        width: 100,
        editable: false,
    },
    {
        field: 'route',
        headerName: 'Route Code',
        headerClassName: 'table-header',
        width: 130,
        editable: true,
    },
    {
        field: 'orh_wave_time',
        headerName: 'Wave Time',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'orh_dispatch_time',
        headerName: 'Dispatch Time',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'orh_end_time',
        headerName: 'End Time',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'orh_actual_duration',
        headerName: 'Duration',
        headerClassName: 'table-header',
        width: 130,
        editable: false
    },
    {
        field: 'unlinked_miles',
        headerName: 'Miles',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'unlinked_congestion',
        headerName: 'Congestion',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'unlinked_tool_crossing',
        headerName: 'Tool Crossing',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'unlinked_away',
        headerName: 'Away',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'orh_late_wave',
        headerName: 'Late Wave',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'rescue2',
        headerName: 'Rescue 2',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'rescue4',
        headerName: 'Rescue 4',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'rescue6',
        headerName: 'Rescue 6',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'support',
        headerName: 'Support',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'deduction',
        headerName: 'Deduction',
        headerClassName: 'table-header',
        width: 130,
        editable: true
    },
    {
        field: 'notes',
        headerName: 'Notes',
        headerClassName: 'table-header',
        width: 180,
        editable: true
    },
    {
        field: 'orh_notes',
        headerName: 'On Road Hours Notes',
        headerClassName: 'table-header',
        width: 180,
        editable: true
    },
    

]

export default columns;