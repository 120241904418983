import React from "react";
import { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import Select from 'react-select';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AuthService from "../../services/auth.service";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { skSK } from "@mui/material/locale";

const colourStylesS = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '100%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '2%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '100%',
        paddingLeft:'1%',
        marginLeft:'2%',
        
    }), 
}
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' , minWidth: '101%', height: '8%', fontSize: 'large', marginLeft:'0px', marginTop: '1%', paddingLeft:'1%', marginLeft: '0%', color: 'rgb(97, 97, 97)',}),
    menu: base => ({
        ...base,
        zIndex: 100,
        width: '101%',
        paddingLeft:'1%',
        marginLeft:'0%',
        
    }), 
}

const statusOptions = [
    {value: 'Pending', label: 'Pending'},
    {value: 'Onboarding', label: 'Onboarding'},
    {value: 'Active', label: 'Active'},
    {value: 'Inactive', label: 'Inactive'},
    {value: 'Offboarded', label: 'Offboarded'},
    {value: 'Not Suitable', label: 'Not Suitable'},
    {value: 'Not Interested', label: 'Not Interested'},
    {value: 'Debtor', label: 'Debtor'}
]

const offRecByOption = [
    {value: 'DA', label: 'DA'},
    {value: 'OSM', label: 'OSM'},
    {value: 'AUTO-OFFBOARD', label: 'AUTO-OFFBOARD'},
]

const offReasonOption = [
    {value: 'Leaver', label: 'Leaver'},
    {value: '90-day', label: '90-day'},
    {value: 'Performance', label: 'Performance'},
    {value: 'Attendance', label: 'Attendance'},
    {value: 'TIER 1', label: 'TIER 1'},
]

const offBadgeStatusOption = [
    {value: 'Returned to station security', label: 'Returned to station security'},
    {value: 'Returned to OSM', label: 'Returned to OSM'},
    {value: 'Returned to Head Office', label: 'Returned to Head Office'},
    {value: 'Not returned', label: 'Not returned'},
]

const offSecAwareOption = [
    {value: 'YES', label: 'YES'},
    {value: 'NO', label: 'NO'},
]

const offActionByOption = [
    {value: 'USER', label: 'USER'},
]

const serviceTypes = [
    {value: 'SD', label: 'Same Day'},
    {value: 'ND', label: 'Next Day'},
    {value: 'HOL', label: 'Holiday'}
]

const EditProfile = () =>{
    const [ names, setNames ] = useState([]) 
    const [ details, setDetails ] = useState()
    const [ value, setValue ] = useState('1')
    const [ name, setName ] = useState()
    const [ station, setStation ] = useState()
    const [ account, setAccount ] = useState()
    const [ role, setRole ] = useState()
    const [ pemail, setPemail ] = useState()
    const [ wemail, setWemail ] = useState()
    const [ phone, setPhone ] = useState()
    const [ address1, setAddress1 ] = useState()
    const [ address2, setAddress2 ] = useState()
    const [ town, setTown ] = useState()
    const [ county, setCounty ] = useState()
    const [ postCode, setPostCode ] = useState()
    const [ dob, setDob ] = useState()
    const [ check, setCheck ] = useState(false)
    const [ entry, setEntry ] = useState()
    const [ source, setSource ] = useState()
    const [ utr, setUtr ] = useState()
    const [ compName, setCompName ] = useState()
    const [ compReg, setCompReg ] = useState()
    const [ compVat, setCompVat ] = useState()
    const [ amzl, setAmzl ] = useState()
    const [ nationality, setNationality ] = useState()
    const [ nino, setNino ] = useState()
    const [ status, setStatus ] = useState()
    const [ bankName, setBankName ] = useState()
    const [ bankHolder, setBankHolder ] = useState()
    const [ bankSc, setBankSc ] = useState()
    const [ bankAc, setBankAc ] = useState()
    const [ bankRef, setBankRef ] = useState()
    const [ payCycle, setPayCycle ] = useState()
    const [ notes, setNotes ] = useState()
    const [ user, setUser ] = useState()
    const [ logCheck, setLogCheck ] = useState(true)
    const [ offReqBy, setOffReqBy ] = useState()
    const [ offReason, setOffReason ] = useState()
    const [ offBadgeStatus, setOffBadgeStatus ] = useState()
    const [ offSecAware, setOffSecAware ] = useState()
    const [ offActionBy, setOffActionBy ] = useState()
    const [ offDate, setOffDate ] = useState(new Date())
    const [ offDCheck, setOffDCheck ] = useState(false)
    const [ dobCheck, setDobCheck ] = useState(false)
    const [ serviceType, setServiceType ] = useState()
    const [ amzlProfile, setAmzlProfile ] = useState()


    useEffect(() => {
        setUser(AuthService.getCurrentUser());  
        console.log(user) 
        UserService.getContactNames().then(
            response =>{
                let dat = []
                response.data?.map(item =>{
                    dat.push({value: item.name, label: item.name})
                })
                setNames(dat)
            }
        ).catch(err =>{
            console.log(err.message)
            if(err.message == "Request failed with status code 403"){
                setLogCheck(false)
            }
        })
    },[])

    
//match
    const handleDriver = (e) =>{
        
        UserService.getProfileData({name: e.value}).then(
            response =>{
                //setDetails(response.data)
                //console.log(response.data)
                console.log(response.data)
                setName(response.data.name)
                setStation(response.data.station ? response.data.station : '')
                setAccount(response.data.account_no ? response.data.account_no : '')
                setRole(response.data.skso_role ? response.data.skso_role : '')
                setPemail(response.data.email ? response.data.email : '')
                setWemail(response.data.email_skso ? response.data.email_skso : '')
                setPhone(response.data.phone ? response.data.phone : '')
                setAddress1(response.data.address1 ? response.data.address1 : '')
                setAddress2(response.data.address2 ? response.data.address2 : '')
                setTown(response.data.town ? response.data.town : '')
                setCounty(response.data.county ? response.data.county : '')
                setPostCode(response.data.post_code ? response.data.post_code : '')
                setDob(response.data.dob ? response.data.dob : '')
                setEntry(response.data.entry ? response.data.entry : '')
                setSource(response.data.vehicle_source ? response.data.vehicle_source : '')
                setUtr(response.data.active_utr ? response.data.active_utr : '')
                setCompName(response.data.active_co_name ? response.data.active_co_name : '')
                setCompReg(response.data.active_co_reg ? response.data.active_co_reg : '')
                setCompVat(response.data.active_co_vat ? response.data.active_co_vat : '')
                setAmzl(response.data.amzl_id ? response.data.amzl_id : '')
                setNationality(response.data.nationality ? response.data.nationality : '')
                setNino(response.data.nino ? response.data.nino : '')
                setStatus(response.data.status ? response.data.status : '')
                setBankName(response.data.active_bank ? response.data.active_bank : '')
                setBankHolder(response.data.active_bank_name ? response.data.active_bank_name : '')
                setBankSc(response.data.active_bank_sort_code ? response.data.active_bank_sort_code : '')
                setBankAc(response.data.active_bank_account_no ? response.data.active_bank_account_no : '')
                setBankRef(response.data.active_bank_ref ? response.data.active_bank_ref : '')
                setPayCycle(response.data.active_pay_cycle ? response.data.active_pay_cycle : '')
                setNotes(response.data.notes ? response.data.notes : '')
                setServiceType(response.data.service_type ? response.data.service_type : '')
                setAmzlProfile(response.data.amzl_profile_link ? response.data.amzl_profile_link : '')  
            }
        )
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const UpdateBasic = () =>{
            let data = {entry: entry, name: name, account_no: account, station: station, skso_role: role, service_type: serviceType, email: pemail, email_skso: wemail, phone: phone, address1: address1, address2: address2, town: town, county: county, post_code: postCode, dob: dob? new Date(dob).toLocaleDateString('en-GB') : '', nationality: nationality, nino: nino, vehicle_source: source, amzl_profile_link: amzlProfile, active_utr: utr, amzl_id: amzl, status: status, offboarding_action_by: offActionBy ? offActionBy : '', offboarding_badge_status: offBadgeStatus ? offBadgeStatus : '', offboarding_date: offDate ? offDate : '', offboarding_reason: offReason ? offReason : '', offboarding_request_by: offReqBy ? offReqBy : '', offboarding_security_aware: offSecAware ? offSecAware : ''}
            
            console.log(data)
            UserService.updateBasicInfo(data).then(
                response => {
                    if(response.status == 200){
                        console.log(response.status)
                        setCheck(true)
                        sleep(3000).then(()=>{
                            setCheck(false)
                            window.location.reload(false)
                        })
                    }
                    
                }
            ).catch(err => console.log(err))
        

    }

    const UpdateAdditional = () =>{
        const data = {entry: entry, active_pay_cycle: payCycle, active_co_name: compName, active_co_reg: compReg, active_co_vat: compVat, active_bank_name: bankName, active_bank: bankHolder, active_bank_ref: bankRef, active_bank_sort_code: bankSc, active_bank_account_no: bankAc, notes: notes}
        UserService.updateAdditionalInfo(data).then(
            response =>{
                if(response.status == 200){
                    console.log(response.status)
                    setCheck(true)
                    sleep(3000).then(()=>{
                        setCheck(false)
                        window.location.reload(false)
                    })
                }
            }
        ).catch(err => console.log(err))
    }

    const closeOffDate = () =>{
        setOffDCheck(false)
    }
    const closeDobDate = () =>{
        setDobCheck(false)
    }


    const handleClick = () =>{
        const data = {assigned_client: 'Skilled Solutions Ltd',  assigned_location: station, type: 'Client', account_no: account, terms: '-2 Weeks', name: name, account_manager: 'SKSO RM', phone_no: phone, email: pemail, address1: address1, address2: address2, town: town, county: county, post_code: postCode, dob: dob, nino: nino , email_skso: wemail, vehicle_source: source}
        UserService.resendCreationSenior(data).then(
            response =>{
                window.location.reload(false)
            }
        )
    }

    return(
        <div className="incidentArch">
            {logCheck ? 
                <>
                    <h3 className="h3TitlesCorrection">Edit Info</h3>
                    <label for="select" className="label" >Associate</label>
                    <Select 
                        options={names}
                        styles={colourStylesS}
                        onChange={e => handleDriver(e)}
                        
                    /> 
                    <Box sx={{ width: '100%', typography: 'body1', marginLeft: '2%', marginTop:'2%' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Basic Info" value="1" />
                                <Tab label="Additional Info" value="2" />
                            </TabList>
                            </Box>
                                <TabPanel value="1">

                                {check == false ? 
                                    <>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="name" className="label2">Name</label>
                                                <input type="text" name="name" className="lone" value={name} onChange={e => setName(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="station" className="label2">Station</label>
                                                <input type="text" name="station" className="inputEdit" value={station} onChange={e => setStation(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="account" className="label2 correction">Account No</label>
                                                <input type="text" name="account" className="inputEdit correction" value={account} onChange={e => setAccount(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="role" className="label2 ">SKSO Role</label>
                                                    <input type="text" name="role" className="lone" value={role} onChange={e => setRole(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="pemail" className="label2">Personal Email</label>
                                                <input type="text" name="pemail" className="inputEdit" value={pemail} onChange={e => setPemail(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="wemail" className="label2 correction">SKSO Email</label>
                                                <input type="text" name="wemail" className="inputEdit correction" value={wemail} onChange={e => setWemail(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="phone" className="label2 ">Phone Number</label>
                                                    <input type="text" name="phone" className="lone " value={phone} onChange={e => setPhone(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="address1" className="label2">Address 1</label>
                                                <input type="text" name="address1" className="inputEdit" value={address1} onChange={e => setAddress1(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="address2" className="label2 correction">Address 2</label>
                                                <input type="text" name="address2" className="inputEdit correction" value={address2} onChange={e => setAddress2(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="town" className="label2 ">Town</label>
                                                    <input type="text" name="town" className="lone " value={town} onChange={e => setTown(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="county" className="label2">County</label>
                                                <input type="text" name="county" className="inputEdit" value={county} onChange={e => setCounty(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="postCode" className="label2 correction">Post Code</label>
                                                <input type="text" name="postCode" className="inputEdit correction" value={postCode} onChange={e => setPostCode(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="dob" className="label2 ">Date Of Birth</label>
                                                    <input type="text" name="dob" className="lone " value={dob} onClick={e => setDobCheck(true)}></input>
                                            </div>
                                        </div>
                                        <div className='calendarL'>
                                            {dobCheck == true ?
                                                <>
                                                    <Calendar  
                                                        onChange={e => {
                                        
                                                            setDob(new Date(e).getDate().toString().padStart(2, '0')+'/'+(new Date(e).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(e).getFullYear())
                                                            closeDobDate()
                                                        }} 
                                                        style={{marginLeft: '200px'}}
                                                        value={dob.toString().split('/').length > 1 ? new Date(dob?.toString().split('/')[2]+'-'+dob?.toString().split('/')[1]+'-'+dob?.toString().split('/')[0]): ''} 
                                                        validRange = {{
                                                            start: new Date(),
                                                            end: null
                                                        }}
                                                        //onBlur={closeDobDate}
                                                        className={'calendarX'}
                                                    />
                                                    {/*<CloseRoundedIcon onClick={closeDobDate}>Close</CloseRoundedIcon>*/}
                                                </> 
                                                : ''
                                            }
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="nationality" className="label2">Nationality</label>
                                                <input type="text" name="nationality" className="inputEdit" value={nationality} onChange={e => setNationality(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="nino" className="label2 correction">Nino</label>
                                                <input type="text" name="nino" className="inputEdit correction" value={nino} onChange={e => setNino(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="source" className="label2">Vehicle Source</label>
                                                <input type="text" name="source" className="lone" value={source} onChange={e => setSource(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="source" className="label2">Amzl Profil Link</label>
                                                <input type="text" name="source" className="lone" value={amzlProfile} onChange={e => setAmzlProfile(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="utr" className="label2">UTR</label>
                                                <input type="text" name="utr" className="inputEdit" value={utr} onChange={e => setUtr(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="amzlId" className="label2 correction">Amzl Id</label>
                                                <input type="text" name="amzlId" className="inputEdit correction" value={amzl} onChange={e => setAmzl(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="status" className="label2">Status</label>
                                                <Select 
                                                    options={statusOptions}
                                                    styles={colourStyles}
                                                    value={{label: status, value: status}}
                                                    onChange={e => setStatus(e.value)}
                                                /> 
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="status" className="label2">Service Type</label>
                                                <Select 
                                                    options={serviceTypes}
                                                    styles={colourStyles}
                                                    value={{label: serviceType, value: serviceType}}
                                                    onChange={e => setServiceType(e.value)}
                                                /> 
                                            </div>
                                        </div>
                                        {
                                            status == 'Offboarded' ? 
                                            <>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Requested by</label>
                                                        <Select 
                                                            options={offRecByOption}
                                                            styles={colourStyles}
                                                            value={{label: offReqBy, value: offReqBy}}
                                                            onChange={e => setOffReqBy(e.value)}
                                                        /> 
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Offboarding Date</label>
                                                        <input type="text" name="amzlId" className="inputEdit correction" value={new Date(offDate).toLocaleDateString('en-GB')} onClick={e => setOffDCheck(true)}></input>
                                                    </div>
                                                </div>
                                                <div className='calendar'>
                                                    {offDCheck == true ?
                                                        <>
                                                            <Calendar  
                                                                onChange={e => setOffDate(e)} 
                                                                style={{marginLeft: '200px'}}
                                                                value={offDate ? new Date(offDate): new Date()} 
                                                                validRange = {{
                                                                    start: new Date(),
                                                                    end: null
                                                                }}
                                                                onBlur={closeOffDate}
                                                                className={'calendarX'}
                                                            />
                                                            <CloseRoundedIcon onClick={closeOffDate}>Close</CloseRoundedIcon>
                                                        </> 
                                                        : ''
                                                    }
                                                </div>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Offboarding Reason</label>
                                                        <Select 
                                                            options={offReasonOption}
                                                            styles={colourStyles}
                                                            value={{label: offReason, value: offReason}}
                                                            onChange={e => setOffReason(e.value)}
                                                        /> 
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Offboarding Badge Status</label>
                                                        <Select 
                                                            options={offBadgeStatusOption}
                                                            styles={colourStyles}
                                                            value={{label: offBadgeStatus, value: offBadgeStatus}}
                                                            onChange={e => setOffBadgeStatus(e.value)}
                                                        /> 
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Offboarding security aware</label>
                                                        <Select 
                                                            options={offSecAwareOption}
                                                            styles={colourStyles}
                                                            value={{label: offSecAware, value: offSecAware}}
                                                            onChange={e => setOffSecAware(e.value)}
                                                        /> 
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="status" className="label2">Offboarding Actioned by</label>
                                                        <Select 
                                                            options={offActionByOption}
                                                            styles={colourStyles}
                                                            value={{label: offActionBy, value: offActionBy}}
                                                            onChange={e => setOffActionBy(e.value)}
                                                        /> 
                                                    </div>
                                                </div>


                                            </>
                                            :''
                                        }
                                        
                                        <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={UpdateBasic}>
                                            <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Update Basic</span>
                                            <div class="fill-container"></div>
                                        </button>
                                    </> :
                                    <label for="select" className="label" >Info Updated!!</label>
                                    }
                                </TabPanel>
                                <TabPanel value="2">
                                {check == false ? 
                                    <>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="cycle" className="label2">Pay Cycle</label>
                                                <input type="text" name="cycle" className="lone" value={payCycle} onChange={e => setPayCycle(e.target.value)}></input>
                                            </div>
                                        </div> 
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="cName" className="label2 ">Company Name</label>
                                                    <input type="text" name="cName" className="lone " value={compName} onChange={e => setCompName(e.target.value)}></input>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="flexComp">
                                                <label for="cReg" className="label2">Company Reg</label>
                                                <input type="text" name="cReg" className="inputEdit" value={compReg} onChange={e => setCompReg(e.target.value)}></input>
                                            </div>
                                            <div className="flexComp">
                                                <label for="cVat" className="label2 correction">Company Vat</label>
                                                <input type="text" name="cVat" className="inputEdit correction" value={compVat} onChange={e => setCompVat(e.target.value)}></input>
                                            </div>
                                        </div>
                                        {user?.department?.match('Senior Management') ? 
                                            <>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                            <label for="bankName" className="label2 ">Bank Name</label>
                                                            <input type="text" name="bankName" className="lone " value={bankName} onChange={e => setBankName(e.target.value)}></input>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="bankHolder" className="label2">Bank Holder</label>
                                                        <input type="text" name="bankHolder" className="inputEdit" value={bankHolder} onChange={e => setBankHolder(e.target.value)}></input>
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="bankRef" className="label2 correction">Bank Ref</label>
                                                        <input type="text" name="bankRef" className="inputEdit correction" value={bankRef} onChange={e => setBankRef(e.target.value)}></input>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <div className="flexComp">
                                                        <label for="sort" className="label2">Bank Sort Code</label>
                                                        <input type="text" name="sort" className="inputEdit" value={bankSc} onChange={e => setBankSc(e.target.value)}></input>
                                                    </div>
                                                    <div className="flexComp">
                                                        <label for="acc" className="label2 correction">Bank Account Number</label>
                                                        <input type="text" name="acc" className="inputEdit correction" value={bankAc} onChange={e => setBankAc(e.target.value)}></input>
                                                    </div>
                                                </div>
                                            </> : ''
                                        }
                                        <div className="flex">
                                            <div className="flexComp">
                                                    <label for="bankName" className="label2 ">Notes</label>
                                                    <textarea rows={3} name="bankName" className="lone " value={notes} onChange={e => setNotes(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    
                                        {
                                        user?.department?.match('Senior Management') ? 
                                        <>
                                            <button class="buttonSkOn" style={{marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={UpdateAdditional}>
                                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>Update Additional</span>
                                                <div class="fill-container"></div>
                                            </button>
                                            <button class="buttonSkOn" style={{marginLeft: '10px', marginTop: '30px', marginButtom: '500px !important', backgroundColor: 'rgb(45, 117, 131)', color:'white'}} onClick={handleClick}>
                                                <span class="button-text" style={{fontSize: '15px', fontWeight: '600'}}>User Creation</span>
                                                <div class="fill-container"></div>
                                            </button>
                                        </>
                                        : ''
                                        }
                                    </> :
                                    <label for="select" className="label" >Info Updated!!</label>
                                    }
                                </TabPanel>
                            
                        </TabContext>
                    </Box>
                </>:
                <>
                    <h3 className="h3TitlesCorrection" style={{color: 'red'}}>You have to be logged in to use this service!</h3>
                </>
            }
           
        </div>
    )
}
export default EditProfile;